
export default function Home() {

    return(
       <>
            <div className="jumbotron p-5">
                <div className="row d-flex justify-content-center">
                    <div className="col-md-6 ">
                        <center>
                            <p className="lead">#comingsoon</p>
                            <h1 
                                style={{ fontSize: 60 }}
                                className="text-primary display-4"
                            >
                                SapioChat
                            </h1>
                        </center>
                        <hr className="my-3"/>
                        <p>
                        An exclusive, amazing and first of its kind dating platform where you are matched based on your brain dynamics instead of appearance.
                        </p>
                    </div>
                </div>
            </div>
       </>
    );
}