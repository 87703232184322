import { createContext, useEffect, useState, useReducer } from "react"
import Reducer from './reducer'
export const Context = createContext()


export const Store = ({ children }) => {
    let initialState = {
        user: {
            name: null,
            email: null,
            accessToken: null,
            refreshToken: null
        },
        notification: {
            message: null,
            type: null
        },
        booking_pending_id: null
      }
    
      const [store] = useState(() => {
          const savedUser = localStorage.getItem("user")
    
          if(savedUser){
              initialState = { ...initialState, user: JSON.parse(savedUser) }
          }
     
          return initialState
      })
    
      const [ state, dispatch ] = useReducer(Reducer, store)
    
      useEffect(()=>{
        localStorage.setItem("user", JSON.stringify(state.user));
      });

      return(
          <Context.Provider value={{ state, dispatch }}>
              { children }
          </Context.Provider>
      );
}