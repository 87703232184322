const TYPES = {
    USER: {
        ADD_USER: 'ADD_USER',
        REMOVE_USER: 'REMOVER_USER',
        REFRESH_ACCESS_TOKEN: 'REFRESH_TOKEN_TOKEN'
    },
    TOAST:{
        SET_NOTIFICATION: 'SET_NOTIFICATION',
        RESET: 'RESET'
    }
}

export default TYPES