import { useLocation, Navigate } from "react-router-dom"

export default function RequireAuth({ children }) {
    const user = JSON.parse(localStorage.getItem("user"))
    let location = useLocation();
      if (!user.accessToken) {
        return <Navigate to="/login" state={{ from: location }} />;
      }  
    return children;
}
  