import Footer from './footer';
import Navbar from './navbar'
import Snackbar from './snackbar'


export default function Container({children}){
    return(
        <>
        {/* <Navbar/> */}
        <div className='container-fluid'>
                {children}
            <Snackbar/>
        </div>
        <Footer/>
        </>
    );
}