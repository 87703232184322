import Home from "./pages/home"

const routes =[
    {
        path: '/',
        name: 'Home',
        Page: Home,
        nav: false,
        prvt: false,
        title: 'SapioChat.'
    }
]


export default routes
