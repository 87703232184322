import TYPES from "./types"
import User from "../service/user"
import Notification from "../service/notifications"

export default function Reducer(state, action) {

    const { type, payload } = action
    const user = new User()
    const notify = new Notification()

    switch(type){
        case TYPES.USER.ADD_USER:
            return user.addUser(state, payload)
        case TYPES.USER.REMOVE_USER:
            return user.removeUser(state)
        case TYPES.USER.REFRESH_ACCESS_TOKEN:
            return user.refreshAccessToken(state, payload)
        case TYPES.TOAST.SET_NOTIFICATION:
            return notify.push(state, payload)
        case TYPES.TOAST.RESET: 
            return notify.pop(state)
        default: 
            return state
    }
}