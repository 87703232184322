import './styles/changes.scss'
import './styles/main.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import RoutesArray from './routes'
import ScrollToTop from './hooks/scrollToTop'
import { Helmet } from 'react-helmet'
import Layout from './components/layout'
import { Store } from './context/store'
import RequireAuth from './utils/privateRoute'

export default function App() {

  return (
    <>
      <Router>
        <Store>
          <Layout>
          <ScrollToTop/>
          <Routes>
          {
            RoutesArray.map( ({ path, Page, title, prvt }, index) => 
             <Route 
                  exact
                  path={path}
                  key={index}
                  element={
                    <>
                    {
                      prvt ? 
                      <RequireAuth>
                        <Helmet>
                          <title>{title}</title>
                          <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
                          <meta name="HandheldFriendly" content="true"></meta>
                        </Helmet>
                        <Page/>
                      </RequireAuth>
                      : <>
                          <Helmet>
                            <title>{title}</title>
                          </Helmet>
                          <Page/>
                        </>
                    }
                    </>
                  }
                />
              )
          }
          </Routes>
          </Layout>
        </Store>
      </Router>
    </>
  )
}

